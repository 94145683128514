<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Price Records {{ priceListId }}</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">
                Home
              </li>
              <li class="breadcrumb-item">
                Pricelists
              </li>
              <li class="breadcrumb-item">
                Pricelist {{ priceListId }}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <PriceListRecordsGrid :priceListId="priceListId"/>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import PriceListRecordsGrid from "../components/pricelists/PriceListRecordsGrid";

export default {
  name: 'PricelistRecords',
  components: {
    Layout,
    PriceListRecordsGrid
  },
  methods: {

  },
  data() {
    return {
      priceListId: this.$route.params.id
    }
  }
}
</script>
