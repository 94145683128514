<script>
export default {
  name: 'StatusDisplayCell',
  data: function () {
    return {
      variant: ''
    };
  },
  beforeMount() {
    this.variant = this.determineVariant(this.params.value);
  },
  methods: {
    determineVariant(value) {
      switch (value) {
        case 'processing':
          return 'light';

        case 'completed':
          return 'success';

        case 'reverted':
          return 'danger';
      }
    }
  }
};
</script>

<template>
  <b-badge :variant="this.variant">{{ this.params.value }}</b-badge>
</template>


<style lang="scss">
.badge {
  padding: 10px 7px;
  text-transform: uppercase;
  letter-spacing: 0.4px;

  &-light {
    background: #eee;
    color: #000;
  }

  &-success {
    background: #cbffc1;
    color: #000;
  }


}
</style>