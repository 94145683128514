<script>


export default {
  name: 'SimplePriceDisplayCell',
  data: function () {
    return {
      price: false,
      currency: false
    };
  },
  beforeMount() {
    if (!this.params.value) {
      return;
    }

    this.price = this.params.value;
    this.currency = this.params.data.currency.code
  }
};
</script>

<template>
  <div>
    <money-format
        :currency-code="this.currency"
        :value="parseFloat(this.price)"
    ></money-format>
  </div>

</template>