<script>
export default {
  name: 'DateCell',
  data: function () {
    return {
      displayValue: ''
    };
  },
  beforeMount() {
    if (this.params.value) {
      this.displayValue = (new Date(this.params.value)).toLocaleString('en-GB');
    }
  }

};
</script>

<template>
  <span>{{this.displayValue}}</span>
</template>

<style lang="scss">
.badge {
  padding: 10px 7px;
  text-transform: uppercase;
  letter-spacing: 0.4px;

  &-light {
    background: #eee;
    color: #000;
  }


}
</style>