<script>
import store from '@/store';

/**
 * This component accepts an array of buttons, each with a type (success, danger, etc.), label and a click handler.
 * The click handler is an object specifying the type of handler: either a store dispatch or an arbitrary method. Both kinds of
 * handlers are provided the value of the parent cell as a parameter.
 */
export default {
  name: 'ActionsDisplayCell',
  data: function () {
    return {
      value: null,
      buttons: []
    }
  },
  beforeMount() {
    this.buttons = this.params.actions;
    this.value = this.params.value;
  },
  methods: {
    handleAction(clickHandler) {

      switch(clickHandler.type) {

        case 'dispatch':
          store.dispatch(clickHandler.action, this.value);
          if (clickHandler['onSuccess']) { clickHandler.onSuccess(); }
          break;

        case 'method':
          clickHandler.action(this.value);
          break;
      }

    }
  }
}
</script>

<template>
  <div>
    <b-button v-for="(button, index) in buttons"
              :key="index" :variant="button.type" size="sm" @click="handleAction(button.clickHandler)" class="mx-lg-1">
      {{ button.label }}
    </b-button>
  </div>

</template>


<style lang="scss">
</style>